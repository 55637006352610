import styled from '@emotion/styled'
import { breakpoints } from '../../utils/styles'
import { copper, skyBlue, oceanBlue, oceanBlue15, oceanBlue30, skyBlue10 } from '../../constants/theme';

export const GridContain = styled.div`
  margin-top: 60px;
  padding-bottom: 60px;
  a {
    color: ${oceanBlue};
    text-decoration: none;
  }

  h1 {
    font-size: 46px;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    text-align: center;
    color: ${skyBlue};
    margin-top: 0;
    line-height: 1.12;
    margin-bottom: 50px;
  }

  h3 {
    letter-spacing: 0.350rem;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    text-align: center;
    font-weight: normal;
    margin-bottom: 21px;
    margin-left: 7px;
  }
`

export const Grid = styled.div`
  display: grid;
  border-top: 2px solid ${copper};
  grid-template-columns: repeat(4, 1fr);
  gap: 2.5rem;
  max-width: 1320px;
  margin: auto;
  background-color: ${skyBlue10};
  padding: 40px;

  @media (max-width: ${breakpoints.l}px){
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${breakpoints.m}px){
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${breakpoints.s}px){
    grid-template-columns: repeat(1, 1fr);
  }
`

export const GridThree = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  max-width: 1320px;
  margin: auto;

  @media (max-width: ${breakpoints.l}px){
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${breakpoints.m}px){
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: ${breakpoints.s}px){
    grid-template-columns: repeat(1, 1fr);
  }
`

export const GridSix = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2.5rem;
  max-width: 1320px;
  margin: auto;

  @media (max-width: ${breakpoints.l}px){
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${breakpoints.m}px){
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: ${breakpoints.s}px){
    grid-template-columns: repeat(1, 1fr);
  }
`



export const CollectionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2.5rem;
  margin: auto;

  @media (max-width: ${breakpoints.xl}px){
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${breakpoints.l}px){
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${breakpoints.s}px){
    grid-template-columns: repeat(1, 1fr);
  }
`

export const Product = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  transition: transform 320ms, box-shadow 320ms;
  box-shadow: 0 0 0 ${oceanBlue30};
  width: 100%;

  &:hover {
    transform: scale(1.05);
    transform-origin: center;
    transition: transform 300ms, box-shadow 300ms;
    box-shadow: 0 2px 10px ${oceanBlue15};
  }

  .gatsby-image-wrapper {
    width: 100%;
    background-color: rgb(100,100,100);
    picture {
      opacity: 0.95;
    }
  }

  a {
    text-decoration: none;
  }
`

export const Title = styled.div`
  font-family: 'HKNova-ExtraBold';
  letter-spacing: 0.10rem;
  font-weight: normal;
  font-size: 15px;
  color: ${oceanBlue};
  margin-bottom: 5px;
  text-align: left;
  margin-top: 10px;
  padding: 0 20px;
  height: 45px;
`

export const Button = styled.button`
  border: 1px solid ${copper};
  border-radius: 3px;
  height: 30px;
  background-color: white;
  cursor: pointer;
`;

export const PriceTag = styled.span`
  font-size: 1.2rem;
  font-family: 'HKNova-Bold';
  text-align: left;
  color: ${copper};
`

export const DiscountedPriceTag = styled.span`
  font-size: 1rem;
  font-family: 'HKNova-Bold';
  text-align: left;
  text-decoration: line-through;
  padding: 0 .5rem;
  color: ${oceanBlue15};
`

export const PriceArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 20px 20px;
`;

export const CollectionReviewStars = styled.div`
  padding: 30px 20px 0 20px;

  .yotpo-bottomline a {
    padding-left: 5px;
  }
`;
