import React from 'react'
import SEO from '../../components/seo'
import RunSaleDetail from '../../components/RunningPageDetail'
import styled from '@emotion/styled';
import ProductSticker from '../../components/productSticker'

import { Container, CopperStripe } from '../../utils/styles';

import { breakpoints } from '../../utils/styles'
import { skyBlue, copper, oceanBlue } from '../../constants/theme';

import hero from '../../images/welcome/running-header.jpg'
import runSticker1 from '../../images/welcome/runners-powerknit.jpg'
import runSticker2 from '../../images/welcome/runners-calf-sleeve.jpg'
import runSticker3 from '../../images/welcome/runners-leggings.jpg'
import runSticker4 from '../../images/welcome/runners-sport-socks.jpg'
import runSticker5 from '../../images/welcome/runners-gatier.jpg'

import theicon1 from '../../images/welcome/icon-1.png'
import theicon2 from '../../images/welcome/icon-2.png'
import theicon3 from '../../images/welcome/icon-3.png'

export const ArthBlockStyle = styled.div`
  background-color: white;
  color: white;
  color: ${oceanBlue};
  padding: 40px;
  max-width: 1320px;
  margin: auto;
  text-align: center;

  img {
    width: 100%;
    max-width: 700px;
  }

  h1 {
    font-size: 46px;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1.12;
    margin-bottom: 30px;

    @media (max-width: ${breakpoints.s}px){
        font-size: 30px;
    }

  }

`;


export const InfoWrap = styled.div`

  text-align: center;
  padding: 10px 40px;
  max-width: 640px;
  margin: auto;
  color: ${oceanBlue};

  p {
    margin-top: 0;
    font-family: 'HKNova-Regular';
    font-size: 24px;
    color: ${oceanBlue};
  }

  h3 {
    font-family: 'HKNova-SemiBold';
    margin-top: 10px;
    margin-bottom: 40px;
    font-size: 18px;
    color: ${oceanBlue};
  }
`

const InfoBlock = (props) => (
  <InfoWrap>
    <p>"{props.uses}"</p>
    <h3>{props.topline}</h3>
  </InfoWrap>
)



export const TitleBlockStyle = styled.div`

  background-color: white;
  color: white;
  color: ${oceanBlue};
  padding: 30px 20px 10px 20px;
  max-width: 1320px;
  margin: auto;
  text-align: center;

  h1 {
    font-size: 46px;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1.12;
    margin-bottom: 30px;
    @media (max-width: ${breakpoints.s}px){
        font-size: 30px;
    }
  }
`

export const SalesBlockStyle = styled.div`
  background-color: white;
  color: white;
  color: ${oceanBlue};
  padding: 10px 40px;
  max-width: 1320px;
  margin: auto;
  text-align: center;

  p {
    font-size: 23px;
    font-family: 'HKNova-Medium';
    letter-spacing: 0.1rem;
    line-height: 1.4;
    max-width: 640px;
    margin: auto;
    margin-bottom: 50px;
    b {
      font-family: 'HKNova-ExtraBold';
    }

    a {
      color: ${oceanBlue};
      text-decoration: none;
    }

  }

  h1 {
    font-size: 46px;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1.12;
    margin-bottom: 30px;
    color: ${skyBlue};

    @media (max-width: ${breakpoints.s}px){
        font-size: 30px;
    }

  }

  h2 {
    font-family: 'HKNova-ExtraBold';
    letter-spacing: 0.10rem;
    font-weight: normal;
    font-size: 18px;
    margin-bottom: 5px;
  }

  h3 {
    letter-spacing: 0.350rem;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 21px;
    margin-left: 7px;
  }


  img {
    border-radius: 100%;
  }

`;

const BlockImg = styled.img`
  width: 100%;
`

const ImageBlock = (props) => (
  <>
    <BlockImg alt={props.title} src={props.img} />
  </>
)

const SalesBlock = () => (
  <SalesBlockStyle>
    <h3>Run With Copper</h3>
    <h1>Get Going</h1>
    <p>Whether you’re high-tailing it with a 400 meter dash or going the distance with a marathon, pain doesnt need to be a part of your routine.</p>
  </SalesBlockStyle>
)

const LandingPage = () => {
  return (
    <>
    <CopperStripe />
    <Container>
      <SEO title="Run with Copper" description="Whether you’re high-tailing it with a 400 meter dash or going the distance with a marathon, pain doesnt need to be a part of your routine." keywords={ [`copper`, `compression`, `pain relief`, `strength to heal`] } />

      <ImageBlock img={hero} title="Runners Day" />
      <SalesBlock />
      <ProductSticker
        showName
        to={'/products/powerknit-knee-sleeve/'}
        img={ runSticker1 }
        bg={ copper }
        title='The PowerKnit'
        caption="Get active! This new knee sleeve is designed to stabilize your joints, prevent injuries and facilitate active recovery so you never lose momentum."
        credit="Features"
        cta="Let's Go!"
        features="Knit construction is breathable to keep you cool and dry and silicone grips keep the sleeve in place during intense activity."
        icon={theicon1}

      />

      <InfoBlock
        uses="I run every day and with the PowerKnit Knee Sleeve, I've noticed a difference in how my legs feel."
        topline='-BEN S. , THE POWER KNIT KNEE SLEEVE'
      />

      <ProductSticker
        showName
        img={ runSticker2 }
        to={'/products/copper-compression-calf-shin-splint-recovery-leg-sleeves/'}
        bg={ '#8bb1d0' }
        title='The Calf Sleeves'
        caption="Don’t let your tendonitis, calf injuries, or shin splits get in the wat. These are great for going the distance."
        cta='Get Moving!'
        credit="Features"
        icon={theicon2}
        features="Targeted compression and temperature regulating material."
      />

      <InfoBlock
        uses="New to running and my calves and knees were hurting. I bought these and they made all the difference in my running stamina."
        topline='- JUAN T. , CALF SLEEVES'
      />



      <ProductSticker
        showName
        to={'/products/womens-leggings-yoga-pants/'}
        bg={ '#99d8e1' }
        img={ runSticker3 }
        title='The Recovery Leggings'
        caption="Perfect for your multifacted cardio routine. Leave your post workout soreness on the track where it belongs."
        cta='Ready Set Go!'
        credit="Features"
        features='Super comfortable fabric, helps with muscle stabilization and post workout soreness.'
        icon={theicon3}
        last
      />

      <InfoBlock
        uses="As a runner, I have a lot of leg stiffness and these are a great complement to stretching and foam rolling."
        topline='-CHRISTINA J. , THE RECOVERY LEGGINGS'
      />

      <ProductSticker
        showName
        to={'/products/copper-compression-knee-high-recovery-support-socks/'}
        img={ runSticker4 }
        bg={ oceanBlue }
        title='The Support Socks'
        caption="“Love your products. I have arthritis and wearing the shirt gives me an added relief so I can reduce my medication!”"
        cta="I'm Ready!"
        credit="Features"
        icon={theicon3}
        features="Targeted compression supports your arches, ankles, and calves from start to finish."
        last
      />

      <InfoBlock
        uses='I love these socks and they seriously work like magic. I used them before i run and they help big time.'
        topline='- SHAWN G. , SPORT SOCKS'
      />

      <ProductSticker
        showName
        to={'/products/copper-compression-protective-neck-gaiter/'}
        img={runSticker5}
        bg={copper}
        title='The Gaiter'
        caption="Protect your neck and face from the elements with Copper Compression’s neck gaiters. The four way flexible fabric construction of our face scarf promises a tight compressive fit around your neck that won't slip or budge while you complete your daily run."
        cta='Off We Go!'
        credit="Features"
        features="Includes a non-slip inner fabric material which ensures your neck gaiter stays on during high movement activities. The materials are light weight and temperature regulated for maximum comfort."
        icon={theicon3}
        last
      />

      <InfoBlock
        uses="This is the best on the market. Light weight and does not move on your face. It can be washed and worn everyday"
        topline='- SAM W. , THE GAITER'
      />

      <RunSaleDetail
        tag='Go For It'
        title='Perfect Gear for Runners'
      />

    </Container>
    </>
  )
}

export default LandingPage
