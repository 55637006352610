import React from 'react'
import { Link } from 'gatsby';
import styled from '@emotion/styled'
import { breakpoints } from '../utils/styles'

const ProductSticker = ({ features, cta, img, title, to, credit, caption, bg }) => {


  const ButtonLink = styled(Link)`
    background-color: rgba(255,255,255,0.9);
    border-radius: 10px;
    padding: 20px 50px;
    display: block;
    color: ${bg};
    letter-spacing: 0.350rem;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    &:hover {
      background-color: rgba(255,255,255,1);
    }
  `


  
  const ProductStickerContain = styled.div`

    display: block;

    .product-sticker {
        background-color: ${bg};
        max-width: 1000px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        margin: auto;
        margin-bottom: 20px;
        img {
          max-width: 100%;
          width: 100%;
          display: block;
        }
      }

    .caption-contain {
      text-align: center;
      height: 100%;
      width: 100%;
      color: white;
      padding: 30px 0;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      flex-direction: column;

      p {
        font-family: 'HKNova-Medium';
        font-weight: normal;
        font-size: 18px;
        max-width: 640px;
        margin: auto;
        padding: 0 20px;
        margin-top: 0;
        margin-bottom: 20px;
      }

      h2 {
        font-family: 'HKNova-ExtraBold';
        letter-spacing: 0.10rem;
        font-weight: normal;
        font-size: 20px;
        margin: auto;
        margin-top: 0;
        margin-bottom: 35px;
        max-width: 420px;

        @media (min-width: ${breakpoints.l}px){
          font-size: 24px;
        }
      }

      h3 {
        letter-spacing: 0.350rem;
        font-family: 'gilroyExtraBold';
        text-transform: uppercase;
        font-weight: normal;
        font-size: 16px;
      }
    }
  `


  return (
    <ProductStickerContain>

      <Link className='product-sticker' to={to}>
        <div>
          <img src={ img } alt="Drew Brees - Strength To Heal" />
        </div>
        <div className='caption-contain'>
          <h2>{title}</h2>
          <p>{caption}</p>
          <h3>{credit}</h3>
          <p>{features}</p>
          <ButtonLink to={to}>{cta}</ButtonLink>
        </div>
      </Link>

    </ProductStickerContain>
  )

}

export default ProductSticker
